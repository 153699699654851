import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
} from '@mui/material';
import { ClientContext } from '../context/ClientContext';

export default function ClientForm({ editedData, onSubmit, onCancel }: any) {
  const {IntegrationVisible, setIntegrationVisible}: any = useContext(ClientContext);
  const [formData, setFormData] = useState(editedData);
  const [open, setOpen] = useState(true);
  const [apiTypeOptions] = useState(['Studio Manager', 'Sale Force', 'other']);
  const [selectedApiType, setSelectedApiType] = useState<string>(editedData.apiType);
  const [showZapier,setShowZapier] = useState(false)
  // let showZapier = false;

  // console.log(IntegrationVisible);
  // console.log(formData.apiType, IntegrationVisible);
  
  
  
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event:any) => {
    event.preventDefault();
    setIntegrationVisible(false);
    const updatedData = {
      ...formData,
      apiType:
        formData.apiType === 'Studio Manager' ? "1" : formData.apiType === 'Sale Force' ? "2" : formData.apiType === 'other' ? null : null,
    };
    setFormData(updatedData);
    onSubmit(updatedData);
    handleClose();
  };
  
  

  const handleClose = () => {
    setShowZapier(false);
    setOpen(false);
    onCancel();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Modification</DialogTitle>
      <DialogContent style={{ padding: '32px' }}>
        <form onSubmit={handleSubmit} >
          <div className='form-client' style={{justifyContent:'center',display:'flex',flexDirection:'row' ,flexWrap:'wrap' }}>

        <div className="form-field" style={{ padding: '10px', display: IntegrationVisible ? 'block' : 'none' }}>
            <Select
              label="ApiType"
              name="apiType"
              value={formData.apiType}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            >
              {apiTypeOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="form-field" style={{ padding: '10px',display: formData.apiType === "Sale Force" && IntegrationVisible === false ? 'block' : 'none' }}>
            <TextField
              label="apiUrl"
              type="text"
              name="apiUrl"
              value={formData.apiUrl}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-field" style={{ padding: '10px' ,display: formData.apiType === "Sale Force" && IntegrationVisible === false ? 'block' : 'none' }}>
            <TextField
              label="clientID"
              type="text"
              name="clientID"
              value={formData.clientID}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-field" style={{ padding: '10px',display: formData.apiType === "Sale Force" && IntegrationVisible === false ? 'block' : 'none' }}>
            <TextField
              label="clientSecret"
              type="text"
              name="clientSecret"
              value={formData.clientSecret}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-field" style={{ padding: '10px',display: formData.apiType === "Sale Force"&& IntegrationVisible === false ? 'block' : 'none' }}>
            <TextField
              label="grantType"
              type="text"
              name="grantType"
              value={formData.grantType}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-field" style={{ padding: '10px', display: showZapier ? 'block' : 'none' }}>
            <TextField
              label="zapierUrl"
              type="text"
              name="zapierUrl"
              value={formData.zapierUrl}
              onChange={handleChange}
              fullWidth
              variant="outlined"

            />
          </div>
          <div className="form-field" style={{padding: '10px', display: IntegrationVisible ? 'none' : (formData.apiType === "Studio Manager" || formData.apiType === "Sale Force") ? 'block' : 'none' }}>
            <TextField
              label="Clt"
              type="text"
              name="clt"
              value={formData.clt}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-field" style={{ padding: '10px', display: IntegrationVisible ? 'none' : (formData.apiType === "Studio Manager" || formData.apiType === "Sale Force") ? 'block' : 'none' }}>
            <TextField
              label="Password"
              type="text"
              name="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              variant="outlined"
             
            />
          </div>
          <div className="form-field" style={{ padding: '10px',  display:'none' }}>
            <TextField
              label="Token"
              type="text"
              name="token"
              value={formData.token}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              style={{ display:'none' }} 
            />
          </div>
          <div className="form-field" style={{ padding: '10px', display: IntegrationVisible === true  ?  'none' : 'block'  }}>
            <TextField
              label="Username"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              
            />
          </div>
          </div>
          <Box display="flex" justifyContent="space-between">
          <Button style={{ padding: '10px', }} onClick={() => {setShowZapier(true); console.log(showZapier);
          }} variant="contained" color="primary">
              zapier
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
