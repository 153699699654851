import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { ColDef } from 'ag-grid-community';
// Define the type for your context value
import {CrmContextValue} from '../interface/CrmContextValue'
import { LoginContext } from './LoginContext';
import { useNavigate  } from 'react-router-dom';
import { DateTime } from 'luxon';

// Create the context
export const CrmContext = createContext<CrmContextValue | undefined>(undefined);

// Define the props for your context provider
interface CrmContextProviderProps {
  children: ReactNode;
}

export function CrmContextProvider({ children }: CrmContextProviderProps) {
  const navigate = useNavigate();
  const [crmData, SetcrmData] = useState<any[]>([]); // this is all the crm data if needed
  const [rowData, setRowData] = useState<any[]>([]); // This is the rows of the collums in the ag-grid 
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]); //In first CRM open this field will be displayed and the other wont 
  const [visibleColumnsCheck, setVisibleColumnsCheck] : any = useState(1)//in session storage so visibleColumns will not change in refresh 
  const [appliedFilters, setAppliedFilters] = useState<{ name: string; fields: string[] }[]>([]);//this is for filterBtn i get the special buttons so it can auto filter what johan wants
  const {isAuthenticated,setIsAuthenticated, openDialog,
    setOpenDialog,
    textDialog, 
    setTextDialog,
    handleCloseDialog,} :any = useContext(LoginContext)
  const sessionIsAuthenticated:any = sessionStorage.getItem('isAuthenticated');
  let sourceID:any = sessionStorage.getItem('sourceID');

  const fetchDataPeriodically = () => { // every 15 min this will trigger for getting data in a hour it will not show data because of the token
    setInterval(() => {
      if (sessionIsAuthenticated) {
        GetData();
      }
    }, 15 * 60 * 1000); // Call GetData every 15 minutes (15 * 60 * 1000 milliseconds)
  };

   useEffect( () => {
    if (sessionIsAuthenticated != null) {
    
       GetData(); 
       fetchDataPeriodically();
       }
      
    // else{setRowData([]);}
    
    if (!sessionIsAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated,sessionIsAuthenticated,navigate]);
  
  
  useEffect(() => {
    const savedData = localStorage.getItem('appliedFilters');
    if (savedData) {  setAppliedFilters(JSON.parse(savedData));}
    if (sessionStorage.getItem('visibleColumns') != '[]' && sessionStorage.getItem('visibleColumns') != null) {

      setVisibleColumns(JSON.parse(sessionStorage.getItem('visibleColumns') as string));
    } else {
      // If no value in sessionStorage, set the default columns
      setVisibleColumns(['Signup_Date','Campaign_Name','First_Name','Last_Name','Email','Phone_Number','Country','Language','FTD','Status','Platform','Click_ID','Click_ID_EXT','Site','CLT',]);
    }
   
  }, []);


  const updateVisibleColumns = () => {
    sessionStorage.setItem('visibleColumns', JSON.stringify(visibleColumns));
  };
  
  useEffect(() => {//this is for filterbtn to save the session of the filter that you want for this session
    if (visibleColumnsCheck !== 1) {
      updateVisibleColumns();
    }
  }, [visibleColumnsCheck]);
 
  
  const columnDefs: ColDef[] = [
    { headerName: 'Signup Date', field: 'Signup_Date', filter: 'agDateColumnFilter',filterParams: {
      comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
       // Parse the cell value into a Luxon DateTime object
        const cellDate = DateTime.fromFormat(cellValue, 'dd/LL/yyyy HH:mm:ss', { zone: 'Asia/Jerusalem' });

        // Ensure filterLocalDateAtMidnight is a Luxon DateTime object
        if (!(filterLocalDateAtMidnight instanceof DateTime)) {
          filterLocalDateAtMidnight = DateTime.fromJSDate(filterLocalDateAtMidnight, { zone: 'Asia/Jerusalem' });
        }

        // Check if cellDate is valid
        if (!cellDate.isValid) {
          return 0;
        }

        // Compare the cellDate with filterLocalDateAtMidnight
        if (cellDate.toISODate() === filterLocalDateAtMidnight.toISODate()) {
          return 0; // Dates are equal
        } else if (cellDate < filterLocalDateAtMidnight) {
          return -1; // Cell date is earlier
        } else {
          return 1; // Cell date is later
        }
      },
    } ,valueGetter: (params: any) => { 
      return DateTime.fromISO(params.data.Signup_Date, { zone: 'Asia/Jerusalem' }).toFormat('dd/LL/yyyy HH:mm:ss');}
  },
  { headerName: 'User ID', field: 'User_ID', filter: 'agTextColumnFilter' },
  { headerName: 'Campaign Name', field: 'Campaign_Name', filter: 'agTextColumnFilter' },
  { headerName: 'First Name', field: 'First_Name', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'Last_Name', filter: 'agTextColumnFilter' },
  { headerName: 'Email', field: 'Email', filter: 'agTextColumnFilter' },
  { headerName: 'Phone Number', field: 'Phone_Number', filter: 'agTextColumnFilter' },
  { headerName: 'Country', field: 'Country', filter: 'agTextColumnFilter' },
  { headerName: 'Language', field: 'Language', filter: 'agTextColumnFilter' },
  { headerName: 'Status', field: 'Status', filter: 'agTextColumnFilter' },
  { headerName: 'Bookings', field: 'bookings', filter: 'agTextColumnFilter' },
  { headerName: 'Arrivals', field: 'arrivals', filter: 'agTextColumnFilter' },
    { headerName: 'FTD', field: 'FTD', filter: 'agTextColumnFilter' },
    { headerName: 'ftdAmt', field: 'ftdAmt', filter: 'agTextColumnFilter' },
    { headerName: 'ftd Date', field: 'ftdDate', filter: 'agDateColumnFilter', filterParams: {
      comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {    
        // Parse the cell value into a Luxon DateTime object
         const cellDate = DateTime.fromFormat(cellValue, 'dd/LL/yyyy HH:mm:ss', { zone: 'Asia/Jerusalem' });  
         // Ensure filterLocalDateAtMidnight is a Luxon DateTime object
         if (!(filterLocalDateAtMidnight instanceof DateTime)) {
           filterLocalDateAtMidnight = DateTime.fromJSDate(filterLocalDateAtMidnight, { zone: 'Asia/Jerusalem' });
         }
 
         // Check if cellDate is valid
         if (!cellDate.isValid) {
           return 0;
         }
 
         // Compare the cellDate with filterLocalDateAtMidnight
         if (cellDate.toISODate() === filterLocalDateAtMidnight.toISODate()) {
           return 0; // Dates are equal
         } else if (cellDate < filterLocalDateAtMidnight) {
           return -1; // Cell date is earlier
         } else {
           return 1; // Cell date is later
         }
       },
     } ,valueGetter: (params: any) => { 
       return DateTime.fromISO(params.data.ftdDate, { zone: 'Asia/Jerusalem' }).toFormat('dd/LL/yyyy HH:mm:ss');}
   },
    { headerName: 'Retention', field: 'Retention', filter: 'agTextColumnFilter' },
    { headerName: 'retAmt', field: 'retAmt', filter: 'agTextColumnFilter' },
    { headerName: 'Total Deposit', field: 'totalDeposit', filter: 'agTextColumnFilter' },
    { headerName: 'Total Deposit Amt', field: 'totalDepositAmt', filter: 'agTextColumnFilter' },
    { headerName: 'Currency', field: 'Currency', filter: 'agTextColumnFilter' },
    { headerName: 'Last Comment', field: 'Last_Comment', filter: 'agTextColumnFilter' },
    { headerName: 'Sent', field: 'Sent', filter: 'agTextColumnFilter' },
    { headerName: 'Platform', field: 'Platform', filter: 'agTextColumnFilter' },
    { headerName: 'Click ID', field: 'Click_ID', filter: 'agTextColumnFilter' },
    { headerName: 'Click ID EXT', field: 'Click_ID_EXT', filter: 'agTextColumnFilter', cellClass:'ag-cell-1',width: 275, },
    { headerName: 'Site', field: 'Site', filter: 'agTextColumnFilter' },
    { headerName: 'SiteID', field: 'SiteID', filter: 'agTextColumnFilter' },
    { headerName: 'Aff ID', field: 'Aff_ID', filter: 'agTextColumnFilter' },
    { headerName: 'aff', field: 'aff', filter: 'agTextColumnFilter' }, 
    { headerName: 'CLT', field: 'CLT', filter: 'agTextColumnFilter' },
    { headerName: 'clt ID', field: 'clt_ID', filter: 'agDateColumnFilter' },
    { headerName: 'Source', field: 'Source', filter: 'agTextColumnFilter' },
    { headerName: 'source ID', field: 'source_ID', filter: 'agTextColumnFilter' },
    { headerName: 'FullUserAgentString', field: 'FullUserAgentString', filter: 'agTextColumnFilter' },
    { headerName: 'Brower Name', field: 'Brower_Name', filter: 'agTextColumnFilter' },
    { headerName: 'BrowserVersion', field: 'Browser_Version', filter: 'agTextColumnFilter' },
    { headerName: 'DeviceBrand', field: 'Device_Brand', filter: 'agTextColumnFilter' },
    { headerName: 'DeviceModel', field: 'Device_Model', filter: 'agTextColumnFilter' },
    { headerName: 'DeviceType', field: 'Device_Type', filter: 'agTextColumnFilter' },
    { headerName: 'operatingSystem', field: 'operating_System', filter: 'agTextColumnFilter' },
    { headerName: 'osVersion', field: 'os_Version', filter: 'agTextColumnFilter' },
    { headerName: 'RenderingEngine', field: 'Rendering_Engine', filter: 'agTextColumnFilter' },
    { headerName: 'Ip', field: 'ip', filter: 'agTextColumnFilter' },
    { headerName: 'varOne', field: 'varOne', filter: 'agTextColumnFilter' },
    { headerName: 'varTwo', field: 'varTwo', filter: 'agTextColumnFilter' },
    { headerName: 'varThree', field: 'varThree', filter: 'agTextColumnFilter' },
    { headerName: 'varFour', field: 'varFour', filter: 'agTextColumnFilter' },
    { headerName: 'varFive', field: 'varFive', filter: 'agTextColumnFilter' },
    { headerName: 'varSix', field: 'varSix', filter: 'agTextColumnFilter' },
    { headerName: 'varSeven', field: 'varSeven', filter: 'agTextColumnFilter' },
    { headerName: 'varEight', field: 'varEight', filter: 'agTextColumnFilter' },
    { headerName: 'varNine', field: 'varNine', filter: 'agTextColumnFilter' },
    { headerName: 'varTen', field: 'varTen', filter: 'agTextColumnFilter',
      cellRenderer: 'copyButtonRenderer', // Reference to a custom renderer
    
    },
  ];
  

 
  


  // Function to validate Base64 strings
const isValidBase64 = (str: string) => {
  if (typeof str !== "string") {
    console.error("Invalid Base64: Expected a string but got:", typeof str);
    return false;
  }

  const cleanedStr = str.replace(/\s/g, ""); // Remove spaces/newlines
  const regex = /^[A-Za-z0-9+/]+={0,2}$/; // Matches only valid base64 characters
  return regex.test(cleanedStr);
};

// Function to convert Base64 to Blob
const base64toBlob = (data: string) => {
  if (typeof data !== "string") {
    console.error("Invalid data type: Expected a string, got:", typeof data);
    return null;
  }

  // Extract MIME type from Base64 string
  const match = data.match(/^data:(image\/(png|jpeg|jpg)|application\/pdf);base64,/);
  if (!match) {
    console.error("Unsupported or invalid Base64 format.");
    return null;
  }

  const mimeType = match[1]; // Extract MIME type (e.g., "image/png" or "application/pdf")
  const base64WithoutPrefix = data.split(",")[1]; // Remove the prefix

  try {
    const byteCharacters = atob(base64WithoutPrefix); // Decode Base64
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  } catch (error) {
    console.error("Base64 decoding failed:", error);
    return null;
  }
};

// Function to handle the download of multiple Base64-encoded files
const handleDownload = (base64Data: string, filePrefix: string = "document") => {
  // Split the Base64 data into multiple parts using the hyphen
  const base64Parts = base64Data.split("-");

  if (base64Parts.length === 0) {
    console.error("No Base64 data found.");
    alert("Failed to generate files. Check console for errors.");
    return;
  }

  base64Parts.forEach((base64Part, index) => {
    const blob = base64toBlob(base64Part);

    if (!blob) {
      console.error(`Failed to generate Blob for part ${index + 1}`);
      return;
    }

    // Determine file extension based on MIME type
    let fileExtension = ".bin"; // Default fallback
    if (blob.type === "application/pdf") fileExtension = ".pdf";
    else if (blob.type === "image/png") fileExtension = ".png";
    else if (blob.type === "image/jpeg") fileExtension = ".jpg";

    // Create a Blob URL
    const url = URL.createObjectURL(blob);

    // Trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filePrefix}${index + 1}${fileExtension}`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
};

// Component to render the download button
const copyButtonRenderer = (params: any) => {
  const handleButtonClick = () => {
    if (!params.value) {
      console.error("No Base64 data found in params.value.");
      alert("No file data available to download.");
      return;
    }

    handleDownload(params.value, "downloaded-file");
  };

  if (!params.value) {
    return null;
  }

  return (
    <button onClick={handleButtonClick} style={{ cursor: "pointer" }}>
      Download File
    </button>
  );
};

  



  
  const handleExportCSV = (gridApi: any) => { 
   
    // Func for making a CSV(excel) file from the table  
    const params = {fileName: 'crm_data.csv',};
    gridApi.exportDataAsCsv(params);
  };

  const handleGridReady = (params: any) => { // Give the api for the grid
    params.current = params.api; // Save gridApi in the ref
  };

  const GetData = async () => {
   
     const queryParams = new URLSearchParams({
      sourceID: sourceID,
      // Add more query parameters as needed
    });
    
    try {
        let response = await fetch(`${process.env.REACT_APP_API_URL}/api/leads?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${sessionIsAuthenticated}`,
        },
      
      });
      let data = await response.json(); // Parse the response data as JSON 
      
      if (response.ok) {
        
        const transformedData: any[] = data.map((item:any) => ({
          Signup_Date: item.signupDate, // Modify this to match your data structure
          Campaign_Name: item.campgainName,
          First_Name: item.firstName,
          Last_Name: item.lastName,
          Email: item.email,
          Phone_Number: item.phoneNumber,
          Country: item.country,
          Language: item.language,
          FTD: item?.ftd?.data[0] ,
          ftdAmt:item.ftdAmt,
          ftdDate: item?.ftdDate,
          Status: item.status,
          Platform: item.platform,
          Click_ID: item.clickID,
          Click_ID_EXT: item.clickIDExt,
          Site: item.site,
          CLT: item.clt,
          clt_ID: item.cltID,
          Browser_Version:item.browserVersion,
          Device_Brand:item.deviceBrand,
          Device_Model:item.deviceModel,
          Device_Type:item.deviceType,
          FullUserAgentString:item.fullUserAgentString,
          Last_Comment:item.lastComment,
          operating_System:item.operatingSystem,
          os_Version:item.osVersion,
          Rendering_Engine:item.renderingEngine,
          SiteID:item.siteID,
          Source:item.source,
          Brower_Name:item.browerName,
          varOne:item.varOne,
          varTwo:item.varTwo,
          varThree:item.varThree,
          varFour:item.varFour,
          varFive:item.varFive,
          varSix:item.varSix,
          varSeven:item.varSeven,
          varEight:item.varEight,
          varNine:item.varNine,
          varTen:item.varTen,
          User_ID:item.userID,
          Aff_ID:item.affID,
          retAmt:item.retAmt,
          Retention:item.retention,
          Sent:item.Sent,
          Currency:item.currency,
          source_ID:item.sourceID,
          aff:item.aff,
          totalDeposit:item.totalDeposit,
          totalDepositAmt:item.totalDepositAmt,
          ip:item.ip,
          arrivals:item.arrivals,
          bookings:item.bookings   
      }));    
      setRowData(transformedData);
      }
       else {
        if(data.message === 'Unauthorized: Invalid token'){
            sessionStorage.removeItem('isAuthenticated');
        }
     
        setTextDialog("problem in GetData ")
        setOpenDialog(true);
        return null; // Return null or throw an error to indicate failure
      }
    } catch (error) {  
      setTextDialog('An error occurred while fetching Data ' )
      setOpenDialog(true);
      return null; // Return null or throw an error to indicate failure
    }
  };

// console.log(rowData[107]);


 
  const value: CrmContextValue = {
    handleExportCSV,
    handleGridReady,
    GetData,
    SetcrmData,
    crmData,
    rowData, 
    setRowData,
    visibleColumns,
    setVisibleColumns,
    columnDefs,
    appliedFilters,
    setAppliedFilters,
    copyButtonRenderer,
    visibleColumnsCheck,
    setVisibleColumnsCheck
  };

  return (
    <CrmContext.Provider value={value}>
      {children}
    </CrmContext.Provider>
  );
}
