import React, { useContext, useEffect,useState,useRef } from 'react'
import { ClientContext } from '../context/ClientContext';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import AlertDialog from '../component/AlertDialog';
import { CrmContext } from '../context/CrmContext';
import { LoginContext } from '../context/LoginContext';
import ClientForm from '../component/ClientForm';
import '../styles/Clients.css'
import { FaClipboard } from 'react-icons/fa';

import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';

// Register all community features
ModuleRegistry.registerModules([AllCommunityModule]);

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: "legacy"});



export default function Clients() {
  const {GetClients,rowData,setRowData,columnDefs, visibleColumns,setVisibleColumns,visibleColumnsCheck,setVisibleColumnsCheck,appliedFilters,setAppliedFilters,editFormVisible,setEditFormVisible,editedData, setEditedData,handleEditFormSubmit,handleEditClick,isConfirmationOpen,setConfirmationOpen,PostBackVisible,setPostBackVisible}: any = useContext(ClientContext);
  const {handleExportCSV,handleGridReady}: any = useContext(CrmContext);
  const {isAuthenticated,setIsAuthenticated,openDialog,textDialog,setTextDialog,handleCloseDialog,} :any = useContext(LoginContext)
  const [copiedMessage, setCopiedMessage]: any = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100;
  const [showColumnButtons, setShowColumnButtons] = useState(false);

  useEffect(()=>{
      GetClients()
  },[])

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rowData.slice(startIndex, endIndex);

  const toggleColumnVisibility = (columnField: string) => {
    setVisibleColumnsCheck(visibleColumnsCheck + 1)
    
    if (visibleColumns.includes(columnField)) {
      setVisibleColumns((prevColumns: string[]) =>
      prevColumns.filter(col => col !== columnField)
      );
    } else {
      setVisibleColumns((prevColumns: string[]) => [...prevColumns, columnField]);
    }
  };

  const toggleFilterColumnVisibility = (filterFields: string[]) => {
    const newVisibleColumns = columnDefs
      .filter((column: ColDef) => filterFields.includes(column.field!))
      .map((column: ColDef) => column.field!);

    setVisibleColumns(newVisibleColumns);
    setVisibleColumnsCheck(visibleColumnsCheck + 1)
  };

  const gridApiRef = useRef<any>(null);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };


  const handleApiUrlClick = (event: React.MouseEvent<HTMLDivElement>) => { // on click on the link it copies it to the clipboard 
    if (PostBackVisible) {
      const apiUrl = event.currentTarget.textContent;
    if (apiUrl !== null) {
      navigator.clipboard.writeText(apiUrl)
      setCopiedMessage({message: 'Copied!',});
       // Reset the message after 1 second
        setTimeout(() => {
          setCopiedMessage(null);
        }, 2000);
    }
         
  }}

  const handleApiUrlLogoClick = (text:any) => {
    if (PostBackVisible) {
      const apiUrl = text;
    if (apiUrl !== null) {
      navigator.clipboard.writeText(apiUrl)
      setCopiedMessage({message: 'Copied!',});
       // Reset the message after 1 second
        setTimeout(() => {
          setCopiedMessage(null);
        }, 2000);
    }
  } }

  const handleClickX = () => {//on click it hides the postback
    setPostBackVisible(null)
  };
 

  //using this to add a new client 
 const dataAdd = {clt: "",password: "",token:"",username: "",sourceID: "",apiType:"",apiUrl:"",clientID:"",clientSecret:"",grantType:"",passwordVisible: false,zapierUrl:"",};

  return (
    <>
    <div className="ag-theme-alpine-dark crm-container">
      <div className="toggle-buttons-crm">
      <button onClick={() => setShowColumnButtons(!showColumnButtons)}>
        Options
      </button>
      <button onClick={() =>handleEditClick(dataAdd)}>
        Add Client
      </button>
      
      {showColumnButtons && columnDefs.map((column: ColDef) => (
          <button
            key={`column-${column.field}`} // Unique key
            className={`toggle-button ${
            visibleColumns.includes(column.field!) ? 'active' : ''}`}
            onClick={() => toggleColumnVisibility(column.field!)}
          >
            {column.headerName}
          </button>
        ))}

      {appliedFilters.map((filter: any) => (
        <button
          key={`filter-${filter.name}`} // Unique key
          className="toggle-button"
          onClick={() => toggleFilterColumnVisibility(filter.fields)}
        >
          Toggle {filter.name} Columns
        </button>
      ))}

      <button onClick={() => handleExportCSV(gridApiRef.current)}>Export to CSV</button>
    </div>

    <div className="pagination-crm">
      <button disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}> &lt; </button>
      <p>{startIndex}</p>
      <button disabled={endIndex >= rowData.length} onClick={() => onPageChange(currentPage + 1)}> &gt; </button>
    </div>
    
    <AgGridReact 
      columnDefs={columnDefs.filter((column: ColDef) =>
        visibleColumns.includes(column.field!)
      )}
      rowData={visibleRows}
      defaultColDef={{
        filter: true,
        sortable: true,
        editable: true,
        
      }}
      domLayout="autoHeight"
      onGridReady={params => {
        gridApiRef.current = params.api;
        handleGridReady(params);
      }}
    />

  {editFormVisible && (
    <ClientForm
      editedData={editedData}
      onSubmit={(formData:any) => {
      handleEditFormSubmit(formData);
      setEditFormVisible(false);
      }}
      onCancel={() => setEditFormVisible(false)}
    />
  )}
  
    <AlertDialog/>
  </div>
  


  {PostBackVisible && (//post back for copying
    <div className='postback-table'>
       <div className='postback-x-button' onClick={handleClickX}>X</div>
      <div className='postback-boooking' >
        <h2>Booking</h2>
        <div className='copies'>
          <p onClick={handleApiUrlClick}>https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&event=BOOKINGS&clt_id={PostBackVisible}</p>
          <span className='copie-ico' role="img" aria-label="copy" onClick={ () => handleApiUrlLogoClick('https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&event=BOOKINGS&clt_id=' + PostBackVisible)}><FaClipboard /></span>
        </div>
      </div>
      <div className='postback-arrival' >
        <h2>Arrival</h2>
        <div className='copies'>
          <p onClick={handleApiUrlClick}>https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&event=ARRIVALS&clt_id={PostBackVisible}</p>
          <span className='copie-ico' role="img" aria-label="copy" onClick={ () => handleApiUrlLogoClick('https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&event=ARRIVALS&clt_id=' + PostBackVisible)}><FaClipboard /></span>
        </div>
      </div>
      <div className='postback-ftd'>
        <h2>Ftd</h2>
        <div className='copies'>
          <p onClick={handleApiUrlClick}>https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&sum=REPLACE&currency=REPLACE&event=FTD&clt_id={PostBackVisible}</p>
          <span className='copie-ico' role="img" aria-label="copy" onClick={ () => handleApiUrlLogoClick('https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&sum=REPLACE&currency=REPLACE&event=FTD&clt_id=' + PostBackVisible)}><FaClipboard /></span>
        </div>
      </div>
      <div className='postback-retention'>
        <h2>Retention</h2>
        <div className='copies'>
          <p onClick={handleApiUrlClick}>https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&sum=REPLACE&currency=REPLACE&event=RETENTION&clt_id={PostBackVisible}</p>
          <span className='copie-ico' role="img" aria-label="copy" onClick={ () => handleApiUrlLogoClick('https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&sum=REPLACE&currency=REPLACE&event=RETENTION&clt_id=' + PostBackVisible)}><FaClipboard /></span>
        </div>
      </div>
      <div className='postback-total-deposit'>
        <h2>Total deposit</h2>
        <div className='copies'>
          <p onClick={handleApiUrlClick}>https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&event=TOTAL_DEPOSIT&sum=REPLACE&currency=REPLACE&clt_id={PostBackVisible}</p>
          <span className='copie-ico' role="img" aria-label="copy" onClick={ () => handleApiUrlLogoClick('https://postback.leadsyncmedia.com/api/leads/updateLead?click_id=REPLACE&event=TOTAL_DEPOSIT&sum=REPLACE&currency=REPLACE&clt_id=' + PostBackVisible)}><FaClipboard /></span>
        </div>
      </div>
      {copiedMessage && (
      <p className='copied-answer'> {copiedMessage.message}</p>
      )}
    </div>
  )}
    </>

  
  )
}
