import React, { createContext, useState, ReactNode,useContext,useEffect,useCallback  } from 'react';
import { ColDef } from 'ag-grid-community';
// Define the type for your context value
import { ClientContextValue } from '../interface/ClientContextValue';
import { LoginContext } from './LoginContext';
import { CrmContext } from './CrmContext';
import ConfirmDialog from '../component/ConfirmDialog';

//import { Link, Navigate } from 'react-router-dom';

// Create the context
export const ClientContext = createContext<ClientContextValue| undefined>(undefined);

// Define the props for your context provider
interface ClientContextProviderProps {
  children: ReactNode;
}

export function ClientContextProvider({ children }: ClientContextProviderProps) {
    const [rowData, setRowData] = useState<any[]>([]); // This is the rows of the collums in the ag-grid 
    const [clientsData, SetClientsData] = useState<any[]>([]); // this is all the crm data if needed
    const [visibleColumns, setVisibleColumns] = useState<string[]>([]); //In first CRM open this field will be displayed and the other wont 
    const [visibleColumnsCheck, setVisibleColumnsCheck] : any = useState(1)//in session storage so visibleColumns will not change in refresh 
    const [appliedFilters, setAppliedFilters] = useState<{ name: string; fields: string[] }[]>([]);//this is for filterBtn i get the special buttons so it can auto filter what johan wants
    const {isAuthenticated,setIsAuthenticated,openDialog, setOpenDialog,textDialog, setTextDialog} :any = useContext(LoginContext)
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [editedData, setEditedData] = useState<any>({});
    const [PostBackVisible, setPostBackVisible] = useState();
    const [IntegrationVisible, setIntegrationVisible] = useState(false);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  
  useEffect(() => {
    const savedData = localStorage.getItem('appliedFiltersClient');
    if (savedData) {  setAppliedFilters(JSON.parse(savedData));}
    if (sessionStorage.getItem('visibleColumnsClient') != '[]' && sessionStorage.getItem('visibleColumnsClient') != null) {
      setVisibleColumns(JSON.parse(sessionStorage.getItem('visibleColumnsClient') as string));
    } else {
      // If no value in sessionStorage, set the default columns
      setVisibleColumns(['clt','cltID','username','remove','edit','post']);
    } 
  }, []);

  const updateVisibleColumns = () => {
    sessionStorage.setItem('visibleColumnsClient', JSON.stringify(visibleColumns));
  };
  
  useEffect(() => {//this is for filterbtn to save the session of the filter that you want for this session
    if (visibleColumnsCheck !== 1) {
      updateVisibleColumns();
    }
  }, [visibleColumnsCheck]);

  //all this handels is for the remove button for the client 
  const handleRemoveClick = () => {
    setConfirmationOpen(true);
  };
  const handleCancelDelete = () => {
    // Close the confirmation dialog
    setConfirmationOpen(false);
  };
 
  const handleConfirmDelete = (data:any) => {
    // Perform your delete action here
    RemoveClient(data)
    // Close the confirmation dialog
    setConfirmationOpen(false);
  };

  const CustomRemoveButtonRenderer =  ({ data, api }: any) => {
    return (
    <>
    <button onClick={handleRemoveClick}>Remove</button>
    <ConfirmDialog open={isConfirmationOpen}
    onClose={handleCancelDelete}
    onConfirm={() =>handleConfirmDelete(data)}/>
    </> );};

  const RemoveClient = async (data:any) => {
    let sourceID:any = sessionStorage.getItem('sourceID'); 
    const apiReq = {cltID:data.cltID,sourceID: sourceID,};

    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/api/leads/deleteSourceClient`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',},
        body:JSON.stringify(apiReq)
        });
      const data = await response.json();  
      if (response.ok) {
        setTextDialog(data.message)
        setOpenDialog(true);
        GetClients();
      }   
      else {
        console.error('Request failed with status:', response.status);
      }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
  
    
    
    const handleEditClick = (data:any) => {
      const editedData = {
        apiType:data.apiType,
        clt: data.clt,
        password: data.password,
        token: data.token,
        username: data.username,
        ...(data.cltID ? { cltID: data.cltID } : {cltID: 0}), // Include cltID only if it exists in data
        apiUrl:data.apiUrl,
        clientID:data.clientID,
        clientSecret:data.clientSecret,
        grantType:data.grantType,
        passwordVisible: false,
        zapierUrl:data.zapierUrl,
      };
      // Set the edited data from the selected row
      setEditedData(editedData);
      // Show the edit form
      setEditFormVisible(true);
  };

  
        
  
  const EditAndAddClient = async (data:any) => {
    // console.log(data);
    
    let sourceID:any = sessionStorage.getItem('sourceID');
    const sessionIsAuthenticated:any = sessionStorage.getItem('isAuthenticated');
    
    const apiReq = {
          ...(data.cltID ? { cltID: data.cltID } : {}),
          sourceID:sourceID ,
          clt: data.clt ,
          username: data.username ,
          password: data.password ,
          token: data.token ,
          apiType:data.apiType,
          apiUrl:data.apiUrl ,
          clientID:data.clientID ,
          clientSecret:data.clientSecret ,
          grantType:data.grantType ,
          passwordVisible: false,
          zapierUrl:data.zapierUrl ,
        };

        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/api/leads/addSourceClient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(apiReq)
        });
    
        if (response.ok) {
            const data = await response.json();  
            setTextDialog(data.message)
            setOpenDialog(true);
            GetClients();
        }      
        else {
            console.error('Request failed with status:', response.status);
          }
        } catch (error) {
            console.error('An error occurred:', error);
        }
}
const CustomPasswordRenderer = ({ value }: any) => {
  
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <span onClick={togglePasswordVisibility}>
      {isPasswordVisible ? value : '********'}
    </span>
  );
};

const CustomEditButtonRenderer = ({ data, api }: any) => {    
  return (
      <button onClick={() =>{setIntegrationVisible(false); handleEditClick(data)}}>edit</button>
      );
  };

const CustomPostBackRenderer = ({ data, api }: any) => {    
  return (
      <button onClick={() =>handlePostBack(data.cltID)}>postback</button>
      );
  };

const CustomIntegrationRenderer = ({ data, api }: any) => {    
  return (
    <button onClick={() =>{ setIntegrationVisible(true); handleEditClick(data)}}>Integration</button>
      );
  };

  const handlePostBack = (e:any) => {
    // Call the EditAndAddClient function to submit the edited data
    setPostBackVisible(e);
  };

const handleEditFormSubmit = (e: React.FormEvent) => {
  // Call the EditAndAddClient function to submit the edited data
  EditAndAddClient(e);
    // Close the edit form
  setEditFormVisible(false);
};



    const columnDefs: ColDef[] = [   
      { headerName: 'Integration',      field: 'apiType',     filter: 'agTextColumnFilter' },
      { headerName: 'ApiUrl',       field: 'apiUrl',      filter: 'agTextColumnFilter' },
      { headerName: 'ClientID',     field: 'clientID',    filter: 'agTextColumnFilter' },
      { headerName: 'ClientSecret', field: 'clientSecret',filter: 'agTextColumnFilter' },
      { headerName: 'Clt',          field: 'clt',         filter: 'agTextColumnFilter' },
      { headerName: 'cltID',        field: 'cltID',       filter: 'agTextColumnFilter' },
      { headerName: 'GrantType',    field: 'grantType',   filter: 'agTextColumnFilter' },
      { headerName: 'username',     field: 'username',    filter: 'agTextColumnFilter' },
      { headerName: 'password',     field: 'password',    filter: 'agTextColumnFilter', cellRenderer: CustomPasswordRenderer, minWidth: 100, cellRendererParams: {}, },
      { headerName: 'token',        field: 'token',       filter: 'agTextColumnFilter' },
      { headerName: 'zapierUrl',    field: 'zapierUrl',   filter: 'agTextColumnFilter' },
      { headerName: 'Edit',         field: 'edit',  editable: false,  cellRenderer: CustomEditButtonRenderer,   minWidth: 100, cellRendererParams: {}, },
      { headerName: 'Remove',       field: 'remove',editable: false,  cellRenderer: CustomRemoveButtonRenderer, minWidth: 100, cellRendererParams: {},},
      { headerName: 'PostBacks',    field: 'post',editable: false,  cellRenderer: CustomPostBackRenderer, minWidth: 100, cellRendererParams: {},},
      { headerName: 'choose Integration',       field: 'Integration',editable: false,  cellRenderer: CustomIntegrationRenderer, minWidth: 100, cellRendererParams: {},},
    ];


    const GetClients = async () => { 
        let sourceID:any = sessionStorage.getItem('sourceID');
        const sessionIsAuthenticated:any = sessionStorage.getItem('isAuthenticated');
    
        //test function to get user
        const queryParams = new URLSearchParams({
          sourceID: sourceID,// Add more query parameters as needed
        });
      
    
        try {
          let response = await fetch(`${process.env.REACT_APP_API_URL}/api/leads/getSourceClients?${queryParams.toString()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionIsAuthenticated}`,
          },
        });
        
        if (response.ok) {
          const data = await response.json();  
          // console.log( data);
          SetClientsData(data)
          const transformedData: any[] = data.map((item:any) => ({
          apiType:item.apiType === 1 ? 'Studio Manager' : item.apiType === 2 ? 'Sale Force' : '',
          apiUrl:item.apiUrl || '',
          clientID:item.clientID || '',
          clientSecret:item.clientSecret || '',
          clt:item.clt || '',
          cltID:item.cltID || '',
          grantType:item.grantType || '',
          password:item.password || '',
          passwordVisible: false || '',
          token:item.token || '',
          username:item.username || '',
          zapierUrl:item.zapierUrl || ''
        }));    
        setRowData(transformedData);
        } else {
          console.error('Request failed with status:', response.status);
        }
        } catch (error) {
          console.error('An error occurred:', error);
        }
};

  const value: ClientContextValue = {
    GetClients,columnDefs,rowData,setRowData,visibleColumns,setVisibleColumns,visibleColumnsCheck,setVisibleColumnsCheck,appliedFilters,setAppliedFilters,handleEditFormSubmit,editFormVisible,setEditFormVisible,editedData, setEditedData,handleEditClick,isConfirmationOpen,setConfirmationOpen,PostBackVisible,setPostBackVisible,IntegrationVisible,setIntegrationVisible,
  };

  return (
    <ClientContext.Provider value={value}>
      {children}
    </ClientContext.Provider>
  );
}
