import React, { useContext, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/Crm.css';
import { CrmContext } from '../context/CrmContext';
import { ColDef } from 'ag-grid-community';
import AlertDialog from '../component/AlertDialog';
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';

// Register all community features
ModuleRegistry.registerModules([AllCommunityModule]);

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: "legacy"});

export default function Crm() {
  const {copyButtonRenderer,visibleColumnsCheck, setVisibleColumnsCheck,handleExportCSV,handleGridReady,crmData,rowData,visibleColumns,setVisibleColumns,columnDefs,appliedFilters,}: any = useContext(CrmContext);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 100;
  const [showColumnButtons, setShowColumnButtons] = useState(false);

  const toggleColumnVisibility = (columnField: string) => {
    setVisibleColumnsCheck(visibleColumnsCheck + 1)
    
    if (visibleColumns.includes(columnField)) {
      setVisibleColumns((prevColumns: string[]) =>
      prevColumns.filter(col => col !== columnField)
      );
    } else {
      setVisibleColumns((prevColumns: string[]) => [...prevColumns, columnField]);
    }
  };

  const toggleFilterColumnVisibility = (filterFields: string[]) => {
    const newVisibleColumns = columnDefs
      .filter((column: ColDef) => filterFields.includes(column.field!))
      .map((column: ColDef) => column.field!);

    setVisibleColumns(newVisibleColumns);
    setVisibleColumnsCheck(visibleColumnsCheck + 1)
  };

  const gridApiRef = useRef<any>(null);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rowData.slice(startIndex, endIndex);

  return (
    <div className="ag-theme-alpine-dark crm-container">
      <div className="toggle-buttons-crm">
        <button onClick={() => setShowColumnButtons(!showColumnButtons)}>
          Options
        </button>

        {showColumnButtons && columnDefs.map((column: ColDef) => (
            <button
              key={column.field}
              className={`toggle-button ${
              visibleColumns.includes(column.field!) ? 'active' : ''
              }`}
              onClick={() => toggleColumnVisibility(column.field!)}
            >
              {column.headerName}
            </button>
          ))}

        {appliedFilters.map((filter: any) => (
          <button
            key={filter.name}
            className="toggle-button"
            onClick={() => toggleFilterColumnVisibility(filter.fields)}
          >
            Toggle {filter.name} Columns
          </button>
        ))}

        <button onClick={() => handleExportCSV(gridApiRef.current)}>Export to CSV</button>
      </div>

      <div className="pagination-crm">
      <button disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}> &lt; </button>
        <p>{startIndex}</p>
        <button disabled={endIndex >= rowData.length} onClick={() => onPageChange(currentPage + 1)}> &gt; </button>
      </div>


      
      <AgGridReact
        columnDefs={columnDefs.filter((column: ColDef) =>
          visibleColumns.includes(column.field!)
        )}
        rowData={visibleRows}
        defaultColDef={{
          filter: true,
          sortable: true,
          editable: true,
        }}
        domLayout="autoHeight"
        onGridReady={params => {
          gridApiRef.current = params.api;
          handleGridReady(params);
        }}
        components={{
          copyButtonRenderer,
        }}
      />
      <AlertDialog/>
    </div>
  );
}